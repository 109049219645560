import React from "react";
import { useCountUp } from "react-countup";
import { useInView } from "react-intersection-observer";

import { ModuleWrapper } from "../moduleWrapper";

const AnimatedStat = ({
  startValue = 0,
  endValue = 100,
  play = false,
  ...props
}) => {
  const countUpRef = React.useRef(null);

  const { start, reset } = useCountUp({
    ref: countUpRef,
    start: startValue,
    end: endValue,
    duration: 2,
    separator: ",",
    decimal: ".",
    decimals: Number.isInteger(endValue) ? 0 : 2,
    onReset: () => console.log("Resetted!"),
    onUpdate: () => console.log("Updated!"),
    onPauseResume: () => console.log("Paused or resumed!"),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  React.useEffect(() => {
    if (play) {
      start();
    }
    return () => {
      reset();
    };
  }, [play]);

  return <div ref={countUpRef} {...props} />;
};

const StatsBlock = ({ config, items }) => {
  const [ref, inView] = useInView({});

  return (
    <ModuleWrapper {...config} revealAnimation>
      <div
        ref={ref}
        className="grid px-gutter gap-y-10 md:grid-cols-4 md:gap-x-10"
      >
        {items.map(({ title, prefix, suffix, ...item }) => (
          <div className="text-center">
            <div className="h-[50px] flex items-center w-full justify-center lg:justify-center lg:h-[100px] text-4xl md:text-8xl text-red">
              <span>{prefix}</span>
              <AnimatedStat {...item} play={inView} className="" />
              <span className="">{suffix}</span>
            </div>
            <h4 className="text-base w-full mt-4">{title}</h4>
          </div>
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default StatsBlock;
